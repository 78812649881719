import React, { lazy, Suspense } from 'react';
import { Box, Composition, Only } from 'atomic-layout';
import { FallBackLoader } from 'shared-components/FallBackLoader';

const CategoryListing = lazy(() => import('./CategoryListing'));
const KeyResources = lazy(() => import('./KeyResources'));
const RelatedArticle = lazy(() => import('./RelatedArticle'));
const RelatedVideo = lazy(() => import('./RelatedVideo'));
const UserlyticsWidget = lazy(() => import('./UserlyticsWidget'));

// This is exists to map the React component names
// to the key names in the firebase feature-config collection
// specifically under "sidebar"
const featuresToComponentMap = {
  'category-listing': CategoryListing,
  'key-resources': KeyResources,
  'related-article': RelatedArticle,
  'related-video': RelatedVideo,
  'userlytics-widget': UserlyticsWidget
};

const RailSidebar = ({ sidebarFeatures = [] }) => {
  const featuresToRender = sidebarFeatures.map((feature, index) => {
    const SidebarElement = featuresToComponentMap[feature];

    if (SidebarElement) {
      // add path name to key to force re-render when path changes
      return (
        <SidebarElement
          index={index}
          key={`${feature}-${window.location.pathname}`}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <div data-testid="sidebar">
      <Only to="lg">
        <Composition
          data-testid="sidebar-mobile"
          width="100%"
          areasMd={`Item1 Item2`}
          areas={`Item1`}
          templateCols={2}
          gutterCol="15px"
        >
          {() => (
            <Suspense fallback={<FallBackLoader />}>
              {featuresToRender}
            </Suspense>
          )}
        </Composition>
      </Only>

      <Only from="lg">
        <Box data-testid="sidebar-desktop" width="300px">
          <Suspense fallback={<FallBackLoader />}>{featuresToRender}</Suspense>
        </Box>
      </Only>
    </div>
  );
};

export { RailSidebar };
