import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { CallUsSVG } from './assets/CallUs';
import { CustomerServiceHours } from './shared/CustomerServiceHours';
import { colors } from './constants';
import { PhoneLink } from './shared/PhoneLink';
import { useTranslation } from 'lib/hooks';

export const ContactAgent = () => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid="auto-sett-auth-contact-agent-container"
      display="flex"
      flexDirection="column"
      gap="48px"
      alignItems="center"
      maxWidth="365px"
    >
      <CallUsSVG />
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography
          variant="displayM30"
          fontWeight="500"
          fontSize="28px"
          textAlign="center"
          lineHeight="33.6px"
        >
          {t('alerts.autoSettlementAuth.pleaseCallUs')}
        </Typography>
        <Typography
          variant="bodyM30"
          fontWeight="300"
          fontSize="16px"
          color={colors.secondaryGrayColor}
        >
          {t('alerts.autoSettlementAuth.weLlGoOverYourSettlementOverThePhone')}
        </Typography>
        <PhoneLink />
      </Box>
      <CustomerServiceHours />
    </Box>
  );
};
