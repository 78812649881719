import React from 'react';
import { Box, Button, Link, Typography, useTheme } from '@achieve/sunbeam';
import { useBrand, useTranslation } from 'lib/hooks';
import { CongratsSVG } from './assets/Congrats';
import { colors } from './constants';
import { getAppSubDomain } from 'shared-components/ProvideDashboardType';
import { analyticsTrackEvent } from 'lib/utils/analytics';

export const Success = ({ firstName }) => {
  const { pureT, t } = useTranslation();
  const subdomain = getAppSubDomain();
  const brand = useBrand();
  const theme = useTheme();
  const phone = brand('contact.customer-service.phone');
  const blue = theme?.palette?.primary?.main ?? '#154199';

  const dashboardPathMap = {
    achieve: '/dashboard/resolution/enrolled-debt',
    fdr: '/dashboard/enrolled-debt',
    turnbull: '/enrolled-debt'
  };

  const visitDashboardHref = dashboardPathMap[subdomain];

  return (
    <Box
      data-testid="auto-sett-auth-success-container"
      display="flex"
      flexDirection="column"
      gap="48px"
      alignItems="center"
      maxWidth="365px"
    >
      <CongratsSVG />
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography
          variant="displayM30"
          fontWeight="500"
          fontSize="28px"
          textAlign="center"
          lineHeight="33.6px"
        >
          {pureT('alerts.autoSettlementAuth.congratulations', { firstName })}
          <br />
        </Typography>
        <Typography
          variant="bodyM30"
          fontWeight="300"
          fontSize="16px"
          color={colors.secondaryGrayColor}
        >
          {t(
            'alerts.autoSettlementAuth.weEmailedYouDetailsAboutYourSettlement'
          )}
        </Typography>
      </Box>
      <Button
        data-testid="auto-sett-auth-success-visit-dash-button"
        sx={{ backgroundColor: blue, textTransform: 'none' }}
        size="large"
        onClick={() => {
          analyticsTrackEvent(
            {
              category: 'auto_email_sett_auth',
              action: 'visit_dashboard_clicked',
              label: 'ras'
            },
            'Visit Dashboard clicked from success page'
          );
        }}
        href={visitDashboardHref}
      >
        {t('alerts.autoSettlementAuth.visitYourDashboard')}
      </Button>
      <Typography
        variant="bodyM30"
        fontWeight="400"
        fontSize="16px"
        textAlign="center"
        color={colors.secondaryGrayColor}
      >
        {t('alerts.autoSettlementAuth.didnTMeanToAuthorizeThisSettlement')}{' '}
        <Link href={`tel:${phone}`}>{phone}</Link>
      </Typography>
    </Box>
  );
};
