// Setting default / fallback colors if theme doesn't load
export const colors = {
  primaryBlueColor: '#154199',
  secondaryGrayColor: '#5c708a'
};

export const views = {
  loading: 'loading',
  success: 'success',
  contactAgent: 'contactAgent',
  tryAgain: 'tryAgain'
};

const analyticsCategory = 'auto_email_sett_auth';
const analyticsLabel = 'ras';

export const analyticsPayloads = {
  success: {
    category: analyticsCategory,
    action: 'success',
    label: analyticsLabel
  },
  failure: {
    category: analyticsCategory,
    action: 'failure',
    label: analyticsLabel
  },
  tryAgain: {
    category: analyticsCategory,
    action: 'retry_click',
    label: 'clicked'
  },
  loading: {
    category: analyticsCategory,
    action: 'loading',
    label: analyticsLabel
  }
};
