import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useQueryParam } from 'lib/hooks';
import { FlatPageLayout } from 'shared-components/Layouts/PageLayout';
import { logger, ReCaptcha } from 'shared-components';
import { Loading } from './Loading';
import { Success } from './Success';
import { ContactAgent } from './ContactAgent';
import { TryAgain } from './TryAgain';
import { views } from './constants';
import { submitSettlementAuth, sendAnalyticsEvent } from './utils';
import LocaleContext from 'lang/LocaleContext';

export const AutoSettlementAuth = () => {
  const { settlementCode } = useParams();
  const queryParams = useQueryParam();
  const { locale } = useContext(LocaleContext);
  const isRetry = queryParams.get('retry') === 'true';
  const [firstName, setFirstName] = React.useState('');
  const [viewToRender, setViewToRender] = React.useState(views.loading);
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(null);
  const reCaptchaRef = useRef(null);

  // Catch all function for setting the correct view in the UI, adding logging and analytics events
  const renderView = React.useCallback(
    (viewName, { eventKey, eventName, settlementCode }) => {
      logger.info(`EMAIL SETTLEMENT AUTH :: ${eventName}`);

      setViewToRender(viewName);
      sendAnalyticsEvent({
        eventKey,
        settlementId: settlementCode,
        name: eventName
      });
    },
    []
  );

  // More on useMutation: https://tanstack.com/query/v4/docs/react/guides/mutations
  const mutation = useMutation({
    mutationFn: () => submitSettlementAuth({ settlementCode }),
    onSettled: (data, error) => {
      // If there's an error, we want to render the contact agent view
      if (error) {
        isRetry
          ? renderView(views.contactAgent, {
              eventKey: 'failure',
              eventName: `Settlement could not be authorized after retry (${error.message})`,
              settlementCode // In this case, we're passing back the raw input from the URL
            })
          : renderView(views.tryAgain, {
              eventKey: 'failure',
              eventName: `Settlement could not be authorized (${error.message})`,
              settlementCode // In this case, we're passing back the raw input from the URL
            });
      }

      // Payload is from the FDR Gateway API
      // More info here: https://miro.com/app/board/uXjVNKZSzpQ=/
      let decodedSettlementId = data?.settlementId;

      switch (data.responseCode) {
        // Settlement authorized (200), or already authorized (201)
        case 200:
        case 201:
          setFirstName(data.firstName);
          renderView(views.success, {
            eventKey: 'success',
            eventName:
              data.responseCode === 200
                ? 'Authorization successful'
                : 'Already authorized',
            settlementCode: decodedSettlementId
          });
          return;
        // Settlement not found (400) or rejected (400)
        case 400:
          renderView(views.contactAgent, {
            eventKey: 'failure',
            eventName: 'Settlement code not found',
            settlementCode: decodedSettlementId
          });
          return;
        // Something else went wrong (500)
        case 500:
          isRetry
            ? renderView(views.contactAgent, {
                eventKey: 'failure',
                eventName: 'Settlement could not be authorized after retry',
                settlementCode: decodedSettlementId
              })
            : renderView(views.tryAgain, {
                eventKey: 'failure',
                eventName: 'Settlement could not be authorized',
                settlementCode: decodedSettlementId
              });
          return;
        // Catch-all for any other response code
        default:
          isRetry
            ? renderView(views.contactAgent, {
                eventKey: 'failure',
                eventName: `Settlement could not be authorized after retry (${data.responseCode})`,
                settlementCode: decodedSettlementId
              })
            : renderView(views.tryAgain, {
                eventKey: 'failure',
                eventName: `Settlement could not be authorized (${data.responseCode})`,
                settlementCode: decodedSettlementId
              });
      }
    }
  });

  /*** We're firing off our mutation when the route mounts, and only want to do it once */
  useEffect(() => {
    if (isReCaptchaVerified === null) {
      renderView(views.loading, {
        eventKey: 'loading',
        eventName: 'reCaptcha verification in progress'
      });
    } else if (isReCaptchaVerified) {
      mutation.mutate({ settlementCode });
    } else {
      // If reCaptcha is not verified, show contact agent view
      renderView(views.contactAgent, {
        eventKey: 'failure',
        eventName: 'reCaptcha verification failed'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReCaptchaVerified, renderView]);

  return (
    <>
      <FlatPageLayout>
        {/*** Preventing a render until we know the correct locale to display.
         *  This avoids a potential race condition and/or a Spanish user seeing the <Loading /> view in English
         */}
        {viewToRender === views.loading && locale ? (
          <Loading />
        ) : viewToRender === views.success ? (
          <Success firstName={firstName} />
        ) : viewToRender === views.contactAgent ? (
          <ContactAgent />
        ) : viewToRender === views.tryAgain ? (
          <TryAgain />
        ) : (
          <ContactAgent />
        )}
      </FlatPageLayout>
      <ReCaptcha
        isReCaptchaVerified={isReCaptchaVerified}
        setIsReCaptchaVerified={setIsReCaptchaVerified}
        reCaptchaRef={reCaptchaRef}
      />
    </>
  );
};
